<template>
  <div
    class="background"
    style="width: 100vw; height: 100vh; padding-left: 30px"
  >
    <v-img
      width="100%"
      height="100vh"
      class="d-flex align-center"
      :src="require(`@/assets/images/svg/points-login-background.svg`)"
    >
      <div
        class="d-flex"
        style="height: 100%"
      >
        <div
          class="hiagoborgaço"
        >
          <p
            class="rotate-tornado"
          >
            Nova <span>TORNADO</span>
          </p>
          <p
            class="rotate-honda"
          >
            Leve a vida numa Honda
          </p>
        </div>

        <div
          class=" containerBanner"
          style=""
        >
          <div class="">
            <v-img
              class="bannerLogin"
              :src="require(`@/assets/images/svg/tornado-banner-login.svg`)"
              alt="banner"
            />
          </div>

          <div class="container-login">
            <div class="container-user">
              <v-card-text class="form-title">
                <p
                  class="mb-2"
                  style=" font-size: 20px; font-weight: 700; line-height: 24.2px;"
                >
                  Bem vindo a Motoca Systems!
                </p>

                <p class="mb-2">
                  Insira os seus dados para acessar os sistemas.
                </p>
              </v-card-text>

              <v-card-text v-if="error401">
                <v-alert
                  text
                  color="error"
                >
                  <small class="d-block mb-0"> <strong>Email</strong> ou <strong>Senha</strong> inválidos. </small>
                </v-alert>
              </v-card-text>

              <v-card-text>
                <v-form
                  class=" px-7"
                  @submit.prevent
                >
                  <v-text-field
                    v-model="email"
                    label="Usuário"
                    class=""
                    color="white"
                    type="email"
                    data-test="email-input"
                    autocomplete="username"
                    outlined
                  />

                  <v-text-field
                    v-model="password"
                    outlined
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Senha"
                    placeholder="Senha"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    hide-details="auto"
                    class="mb-2"
                    color="white"
                    data-test="password-input"
                    autocomplete="current-password"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  />

                  <v-btn
                    :loading="isLoading"
                    block
                    type="submit"
                    class="mt-6 py-5"
                    style="background: #1D8271; color: #ffffff; font-weight: 600; font-size: 15px; line-height: 18.15px;"
                    data-test="button-login"
                    @click="login"
                  >
                    ACESSAR
                  </v-btn>
                </v-form>
              </v-card-text>

              <div
                class="container-director"
                @click="$router.push({ name: 'director-channel' })"
              >
                <img
                  src="@/assets/images/svg/chat-icon.svg"
                  alt=""
                />
                <span>
                  Fale com o Diretor
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="logoPosition">
        <v-img
          width="170px"
          height="60px"
          :src="require(`@/assets/images/svg/motoca-systems-logo-login.svg`)"
          alt="logo"
        />
        <div></div>
      </div>
    </v-img>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import axiosIns from '@axios'
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')

    const error401 = ref(false)
    const isLoading = ref(false)

    const typeUser = ref('')

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      isLoading,
      error401,
      typeUser,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
  methods: {
    async login() {
      this.isLoading = true
      this.error401 = false
      await axiosIns
        .post('/api/login', {
          login: this.email,
          password: this.password,
        })
        .then(async res => {
          const { access_token: accessToken, user } = res.data.data
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('username', user.name)
          localStorage.setItem('userRole', user.supervisor)
          localStorage.setItem('congratulation', true)

          await this.$router.push({ name: 'home' })
        })
        .catch(err => {
          this.isLoading = false

          if (err?.response?.status === 401) {
            this.error401 = true
          }
        })

      this.$router.go()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.container-director {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 10px;

  span {color: white;}
  cursor: pointer;

  span:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.459);
  }
}
.background{
  background: #EF7721;
}

.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;

  @media (max-width: 600px) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.container-user {
  width: 400px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #000000D9;
  color: #fff;
  border-radius: 20px;

  @media (max-width: 600px) {
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    width: 350px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #000000D9;
    color: #fff;
    border-radius: 10px;
  }
}
.hiagoborgaço .rotate-tornado{
  position: absolute;
  left: -30px;
  top: 53px;
  width: 150px;
  transform: rotate(-90deg);
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.hiagoborgaço .rotate-honda{
  position: absolute;
  left: -70px;
  bottom: 85px;
  width: 230px;
  transform: rotate(-90deg);
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.logoPosition{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  @media (max-height: 710px) {
    top: 20px;
  }
  @media (max-width: 713px) {
    transform: translateX(-70%);
  }
  @media (max-height: 661px) and (max-width: 1307px) {
    display: none;
  }
}

.rotate-tornado span{
  font-weight: bolder;
}

.text-logo{
  color: #fff
}

.form-title {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bannerLogin {
  width: 100%;

  @media (max-width: 1307px) {
    display: none;
  }
}

.containerBanner{
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 350px;
  width: 100%;
  margin: 150px 100px 150px 0px;
  @media (max-width: 1307px) {
    justify-content: center
  }
  @media (max-width: 1712px) {
    gap: 250px;
    margin: 150px 50px 150px 0px;
  }
  @media (max-width: 1554px) {
    gap: 200px;
    margin: 150px 0px 150px 0px;
  }
  @media (max-width: 1428px) {
    gap: 120px;
    margin: 150px 0px 150px 0px;
  }
  @media (max-width: 1352px) {
    gap: 100px;
    margin: 150px 0px 150px 0px;
  }
  @media (max-width: 1351px) {
    gap: 50px;
    margin: 150px 0px 150px 0px;
  }
}

.hiagoborgaço {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-right: 2px solid #000;
  width: 63px;
  flex-direction: column;

  @media (max-width: 713px) {
    display: none;
  }

  @media (max-height: 835px) {
    height: 92vh;
    margin-top: 22px;
  }

  @media (max-height: 810px) {
    margin-top: 35px;
  }

  @media (max-height: 790px) {
    margin-top: 40px;
  }

  @media (max-height: 770px) {
    margin-top: 50px;
  }

  @media (max-height: 755px) {
    margin-top: 60px;
  }

  @media (max-height: 733px) {
    margin-top: 70px;
  }
  @media (max-height: 710px) {
    margin-top: 80px;
  }
  @media (max-height: 690px) {
    margin-top: 90px;
  }
  @media (max-height: 670px) {
    margin-top: 100px;
  }
  @media (max-height: 650px) {
    margin-top: 110px;
  }
  @media (max-height: 630px) {
    margin-top: 120px;
  }
  @media (max-height: 610px) {
    margin-top: 130px;
  }
  @media (max-height: 590px) {
    margin-top: 140px;
  }
  @media (max-height: 570px) {
    margin-top: 150px;
  }
  @media (max-height: 550px) {
    margin-top: 160px;
  }
  @media (max-height: 530px) {
    margin-top: 170px;
  }
  @media (max-height: 510px) {
    margin-top: 180px;
  }
}

</style>
